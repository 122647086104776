export const classType = [
  {
    value: "privat",
    label: "Privat",
  },
  {
    value: "group",
    label: "Group",
  },
];
