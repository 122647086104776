import React from "react";
import { styled } from "@mui/material/styles";
import { FormControl, InputLabel, TextField } from "@mui/material";
import PropTypes from "prop-types";

export const CustomTextField = styled(TextField)({
  input: {
    fontSize: "12px",
  },
  ".MuiInputBase-root": { borderRadius: "15px" },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(115, 125, 170, 0.70)",
  },
});

const CustomTextarea = React.forwardRef((props, ref) => {
  const { id, label, required, errorMessage, ...otherProp } = props;

  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor={id}
        sx={{ position: "relative", transform: "none", fontSize: "12px", fontWeight: "bold", marginBottom: "5px" }}
      >
        {String(label).toUpperCase()}
        {required && "*"}
      </InputLabel>
      <TextField multiline maxRows={4} id={id} required={required} ref={ref} helperText={errorMessage} {...otherProp} />
    </FormControl>
  );
});

export default function TextareaBasic(props) {
  return <CustomTextarea {...props} />;
}

CustomTextarea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
};
