export const bookingType = [
  {
    value: "",
    label: "Semua Tipe",
  },
  {
    value: "privat",
    label: "Private",
  },
  {
    value: "group",
    label: "Group",
  },
];

export const bookingTypeObj = {
  privat: {
    value: "privat",
    label: "Private",
  },
  group: {
    value: "group",
    label: "Group",
  },
};
